var generic;

generic = generic || {};

(function ($) {
  var contentBlockSignup = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $modules = $('.js-content-block-signup-v1', context);
      var $contentText = $('.js-content-block-signup-content-text', context);
      var $emailForm = $();
      var $emailInput = $();
      var $emailSuccessMessage = $();
      var $emailErrorMessage = $();
      var $emailAlreadySignedUpMessage = $();
      var errorMessage = '';
      var alreadySignedupMessage = '';
      var params = {};
      var $thisModule = $();
      var $languageIdInput = $();
      var selected_locale = '';
      var languageSettings = Drupal
                             && Drupal.settings
                             && Drupal.settings.common.translation_locales;
      var language_id = 48;

      if (!$modules.length) {
        return;
      }

      $modules.each(function () {
        $thisModule = $(this);
        $emailForm = $thisModule.find('.js-content-block-signup__form');
        $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        $emailSuccessMessage = $('.js-content-block-signup__success', $emailForm);
        $emailErrorMessage = $('.js-content-block-signup__error', $emailForm);
        $emailAlreadySignedUpMessage = $('.js-content-block-signup__already-signed-up', $emailForm);
        errorMessage = $thisModule.data().errorText || '';
        alreadySignedupMessage = $thisModule.data().signedUpText || '';
        $languageIdInput = $('input[name="LANGUAGE_ID"]', $emailForm);

        setTimeout(function () {
          selected_locale = generic.cookie('LOCALE');

          // Updating the Language ID after the site is translated.
          if (typeof selected_locale !== 'undefined' && languageSettings) {
            language_id = languageSettings[selected_locale];

            if (language_id) {
              $languageIdInput.val(language_id);
            }
          }
        }, 5000);

        $emailInput.on('keyup', function () {
          if ($(this).val() === '') {
            $(this).removeClass('non-empty');
          } else {
            $(this).addClass('non-empty');
          }
        });

        $emailForm.once($emailForm).on('submit', function (e) {
          e.preventDefault();
          $emailForm.removeClass('content-block-signup--active-error');
          $emailSuccessMessage
            .add($emailErrorMessage)
            .add($emailAlreadySignedUpMessage)
            .addClass('hidden');
          $emailInput.removeClass('error');

          // String into array of form elements
          $emailForm.serializeArray().forEach(function (kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              $emailForm.addClass('content-block-signup--active-success');
              $contentText.addClass('content-block-signup--active-success');

              if (alreadySignedUp) {
                $emailAlreadySignedUpMessage.removeClass('hidden').html(alreadySignedupMessage);
              } else {
                $emailSuccessMessage.removeClass('hidden');
              }
              $emailSuccessMessage.removeClass('hidden');
              $emailInput.val('').removeClass('non-empty');
              $(document).trigger('contentBlock.signupSuccess');
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();
              var errors = '';
              var i;
              var myErr;

              $emailErrorMessage.removeClass('hidden');
              for (i = 0; i < errObjs.length; i++) {
                myErr = errObjs[i];
                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    errors += myErr.text;
                    $emailInput.addClass('error').focus();
                  }
                }
              }
              if (errorMessage) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errorMessage);
              } else if (errors) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errors);
              }
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.contentBlockSignupV1 = {
    attached: false,
    attach: function (context) {
      contentBlockSignup.setup(context);
    }
  };
})(jQuery);
